<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <user-metrics />
      </v-col>
      <v-col cols="12">
        <company-metrics />
      </v-col>

      <v-col cols="12">
        <sales-per-insurer />
      </v-col>

      <v-col cols="12">
        <sales-graph />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import CompanyMetrics from "../../components/Base/CompanyMetrics.vue";
import SalesGraph from "../../components/Base/SalesGraph.vue";
import SalesPerInsurer from "../../components/Base/SalesPerInsurer.vue";
import UserMetrics from "../../components/Base/UserMetrics.vue";

export default {
  name: "Reporting",
  components: {
    UserMetrics,
    CompanyMetrics,
    SalesPerInsurer,
    SalesGraph,
  },
};
</script>
